import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Badge.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Breadcrumb.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Button.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Card.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/common/components/Loading/LoadingArticles.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/CategoryDescription/CategoryDescription.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/Breadcrumb/Breadcrumb.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/CategoryCard/CategoryCard.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/Articles/ArticleDetails/articleDetails.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductCarousel/ProductCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductDetails/ProductFiles/productFiles.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/DynamicPage/dynamicPage.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/Articles/ArticleCard/articleCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductList/ProductsAndConfiguratorsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/actions/AddProductToCart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/actions/RequestOfferProduct.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/ConfiguratorPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/DoorProduct.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/GlassProduct.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/productPage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductPage/productSpecifications/productSpecifications.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductStock/ProductStock.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ProductThumbnailGallery/ProductThumbnailGallery.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/ReadMore/ReadMore.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/SubCategory/subCategory.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/components/SwipperCarousel/SwipperCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/eco-design.svg");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/a_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/a+_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/a++_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/b_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/c_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/energyclass/d_arrow.png");
;
import(/* webpackMode: "eager" */ "/var/www/seminee-bucuresti/_work/pefoc-hydra/pefoc-hydra/src/images/pro-icon.png");
